import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { IbanPipe } from 'ngx-iban';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import {
    ContractTypeShortPipe,
    CountryCropPipe,
    HumanizeFilesizePipe,
    PartnerCurrencyPipe,
    PartnerIdKeyPipe,
} from './pipes';

export function provideCommon(): EnvironmentProviders {
    return makeEnvironmentProviders([
        MessageService,
        DialogService,

        CurrencyPipe, // NOTE: Used in money.pipe.ts
        DecimalPipe, // NOTE: Used in money.pipe.ts
        IbanPipe,
        HumanizeFilesizePipe,
        ContractTypeShortPipe,
        PartnerCurrencyPipe,
        PartnerIdKeyPipe,
        CountryCropPipe,
    ]);
}
