import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type TransactionState = 'Open' | 'Pending' | 'Paid' | 'Failed' | 'Locked';

export function injectTransactionStates(): OverviewDto<TransactionState>[] {
    const translate = inject(TranslateService);

    return [
        { key: 'Open', name: translate.instant('paymentStates.open'), color: 'gray' },
        { key: 'Pending', name: translate.instant('paymentStates.pending'), color: 'yellow' },
        { key: 'Paid', name: translate.instant('paymentStates.paid'), color: 'green' },
        { key: 'Failed', name: translate.instant('paymentStates.failed'), color: 'red' },
        { key: 'Locked', name: translate.instant('paymentStates.locked'), color: 'orange' },
    ];
}
