import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type TransactionType = 'Credit' | 'Debit' | 'Reversal';

export function injectTransactionTypes(): OverviewDto<TransactionType>[] {
    const translate = inject(TranslateService);

    return [
        { key: 'Credit', name: translate.instant('transactionTypes.credit'), color: null },
        { key: 'Debit', name: translate.instant('transactionTypes.debit'), color: null },
        { key: 'Reversal', name: translate.instant('transactionTypes.reversal'), color: null },
    ];
}
