import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type BankAccountType = 'Bank';

export function injectBankAccountTypes(): OverviewDto<BankAccountType>[] {
    const translate = inject(TranslateService);

    return [{ key: 'Bank', name: translate.instant('bankAccountTypes.bank'), color: null }];
}
