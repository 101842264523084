import { inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private readonly messageService = inject(MessageService);

    public showSuccess(options: { message: string }) {
        this.messageService.add({
            severity: 'success',
            detail: options.message,
        });
    }

    public showError(options: { message: string }) {
        this.messageService.add({
            severity: 'error',
            detail: options.message,
        });
    }
}
