import { inject } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageFn, ValidationMessageResolver } from '../../models';
import { isDigits } from '../../utils';

export const serbianBankNumberValidator: ValidatorFn = (control: AbstractControl<string | undefined | null>) => {
    const value = control.value ?? '';
    if (value === '') {
        return null;
    }

    if (value.length !== 18) {
        return { serbianBankNumber: true };
    }

    if (!isDigits(value)) {
        return { serbianBankNumber: true };
    }

    const number = BigInt(value.substring(0, 16));
    const checksum = BigInt(value.substring(16));

    const check = 98n - ((number * 100n) % 97n);
    if (check !== checksum) {
        return { serbianBankNumber: true };
    }

    return null;
};

export function withSerbianBankNumberValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);

        return new Map<string, ValidationMessageResolver>([
            ['serbianBankNumber', () => translate.instant('validation.serbianBankNumberInvalid')],
        ]);
    };
}
