import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type MessageType = 'email' | 'sms';

export function injectMessageTypes(): OverviewDto<MessageType>[] {
    const translate = inject(TranslateService);
    return [
        { key: 'email', name: translate.instant('messageTypes.email'), color: null },
        { key: 'sms', name: translate.instant('messageTypes.sms'), color: null },
    ];
}
