import { Directive, inject, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { ProductCategory } from '../enums';
import { Permission } from '../models';
import { RolesService } from '../services/roles.service';

@Directive({
    selector: '[nuisHasPermission]',
    standalone: true,
})
export class HasPermissionDirective implements OnChanges {
    private readonly templateRef = inject<TemplateRef<unknown>>(TemplateRef);
    private readonly viewContainer = inject(ViewContainerRef);
    private readonly rolesService = inject(RolesService);

    @Input() public nuisHasPermission: Permission | undefined | null;
    @Input() public nuisHasPermissionProduct: ProductCategory | null | undefined;
    @Input() public nuisHasPermissionPartner: string | null | undefined;

    public ngOnChanges() {
        const isGranted = this.rolesService.isGranted(
            this.nuisHasPermission ?? null,
            this.nuisHasPermissionProduct,
            this.nuisHasPermissionPartner,
        );

        this.viewContainer.clear();

        if (isGranted) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
