import { DateTime } from 'luxon';
import { ReversalReason, ReversalStatus, TransactionState, TransactionType } from '../enums';

export interface TransactionDto {
    id: string;
    transactionNr: number;
    refId: string | null;
    refName: string | null;
    contractId: string;
    transactionType: TransactionType;
    billingText: string | null;
    start: DateTime | null;
    end: DateTime | null;
    amount: number | null;
    currency: string | null;
    paymentState: TransactionState;
    errorMessage: string | null;
    collectedOn: DateTime | null;
    createdOn: DateTime | null;
    reversalReason: ReversalReason | null;
    reversalStatus: ReversalStatus | null;
    linkedTransactionId: string | null;
    addition: string | null;
}

export function mapTransactionDto(dto: TransactionDto, index: number) {
    return {
        ...dto,
        transactionNr: index + 1,
        createdOn: dto.createdOn != null ? DateTime.fromISO(dto.createdOn as never as string) : null,
        start: dto.start != null ? DateTime.fromISO(dto.start as never as string) : null,
        end: dto.end != null ? DateTime.fromISO(dto.end as never as string) : null,
    };
}
