<nuis-card>
    <div class="flex gap-3">
        <div class="flex-1 flex flex-column gap-1">
            <div class="flex-1 flex gap-3">
                <div class="flex-1 flex flex-column gap-2 break-words">
                    <div class="font-bold">{{ fullName() }}</div>

                    <div class="flex gap-2">
                        <nuis-display-date
                            class="w-4"
                            [label]="'dateOfBirth' | translate"
                            [value]="person()?.dateOfBirth ?? null" />

                        <nuis-display-text
                            class="w-6"
                            [ngClass]="{ 'w-8': (person()?.phoneNumber ?? null) === null }"
                            [label]="'nationalIdentificationNumber' | translate"
                            [value]="person()?.nationalIdentificationNumber ?? null" />

                        @if (person()?.phoneNumber; as phoneNumber) {
                            <nuis-display-text class="w-4" [label]="'phone' | translate" [value]="phoneNumber" />
                        }
                    </div>
                </div>

                @if (canEdit() || canRemove()) {
                    <div class="flex gap-2 align-items-center">
                        @if (canEdit()) {
                            <p-button [text]="true" size="small" icon="pi pi-pencil" (onClick)="edit.emit()" />
                        }
                        @if (canRemove()) {
                            <p-button
                                [text]="true"
                                size="small"
                                severity="danger"
                                icon="pi pi-trash"
                                (onClick)="remove.emit()" />
                        }
                    </div>
                }
            </div>
        </div>
    </div>
</nuis-card>
