export function isNullOrEmpty(input: string | undefined | null) {
    return input == null || input.trim() === '';
}

export function trimToUndefined(input: string | undefined | null): string | undefined {
    return isNullOrEmpty(input) ? undefined : input!.trim();
}

const digitRegex = /^[0-9]+$/;

export function isDigits(value: string): boolean {
    return digitRegex.test(value);
}
