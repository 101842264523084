import { DateTime } from 'luxon';
import { defaultTransactionsSorter } from '../utils';
import { mapTransactionDto, TransactionDto } from './transaction.dto';

export interface RelatedTransactionDto {
    relatedObject: RelatedObjectDto;
    transactions: TransactionDto[];
}

export interface RelatedObjectDto {
    id: string;
    startedOn: DateTime | null;
    endsOn: DateTime | null;
    travelStatus: string | null;
    subStatus: string | null;
}

export function mapRelatedTransactionDto(r: RelatedTransactionDto) {
    return {
        ...r,
        relatedObject: {
            ...r.relatedObject,
            startedOn:
                r.relatedObject.startedOn != null
                    ? DateTime.fromISO(r.relatedObject.startedOn as never as string)
                    : null,
            endsOn: r.relatedObject.endsOn != null ? DateTime.fromISO(r.relatedObject.endsOn as never as string) : null,
        },
        transactions: r.transactions
            .map((dto) => ({
                dto: dto,
                createdOn: dto.createdOn != null ? DateTime.fromISO(dto.createdOn as never as string) : null,
                start: dto.start != null ? DateTime.fromISO(dto.start as never as string) : null,
            }))
            .sort(defaultTransactionsSorter)
            .map(({ dto }, index) => mapTransactionDto(dto, index)),
    };
}
