import { inject, Pipe, PipeTransform } from '@angular/core';
import { Configuration } from '../models';

@Pipe({
    name: 'partnerIdKey',
    standalone: true,
})
export class PartnerIdKeyPipe implements PipeTransform {
    private readonly config = inject(Configuration);

    public transform(value: string, underscore = false): string {
        for (const key in this.config.partnerIds) {
            if (this.config.partnerIds[key as keyof typeof this.config.partnerIds].toLocaleLowerCase() == value) {
                return underscore ? key : key.replace('_', '');
            }
        }

        return '';
    }

    public transformBack(value: string): string {
        if (value === 'admin') {
            return 'admin';
        }
        if (value === 'h3a') {
            return this.config.partnerIds['h3a_aut'];
        }
        return this.config.partnerIds[insert(value, 2, '_') as keyof typeof this.config.partnerIds] ?? '';
    }
}

export function insert(original: string, index: number, string: string): string {
    const ind = index < 0 ? original.length + index : index;
    return original.substring(0, ind) + string + original.substring(ind + string.length - 1);
}
