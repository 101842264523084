import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type NotificationType =
    | 'welcome'
    | 'start'
    | 'end'
    | 'startsuspended'
    | 'proposalLink'
    | 'deactivationSuccess'
    | 'startTravelWarning'
    | 'cancellationbycustomer'
    | 'cancellationbyinsurer'
    | 'futureterminationdate'
    | 'cancellationDueToOldAge'
    | 'additionalInsuredPersonPreRemoval'
    | 'additionalInsuredPersonRemoval'
    | 'coverChangeSuccess'
    | 'newCountryTravelWarning'
    | 'updatemsisdn'
    | 'policyendorsementgeneral'
    | 'partialTravelWarning'
    | 'exceededtraveltermination'
    | 'ExceededTravelTermination'
    | 'contractSuspended'
    | 'deactivationFailedExpired'
    | 'contractSuspendedNoTravel'
    | 'proposalLegalInformation'
    | 'PromotionExpiredNotification'
    | 'updateCover';

export function injectNotificationTypes(): OverviewDto<NotificationType>[] {
    const translate = inject(TranslateService);
    return [
        { key: 'welcome', name: translate.instant('notificationTypes.welcome'), color: null },
        { key: 'start', name: translate.instant('notificationTypes.start'), color: null },
        { key: 'end', name: translate.instant('notificationTypes.end'), color: null },
        { key: 'startsuspended', name: translate.instant('notificationTypes.startsuspended'), color: null },
        { key: 'proposalLink', name: translate.instant('notificationTypes.proposalLink'), color: null },
        {
            key: 'deactivationSuccess',
            name: translate.instant('notificationTypes.deactivationSuccess'),
            color: null,
        },
        {
            key: 'startTravelWarning',
            name: translate.instant('notificationTypes.startTravelWarning'),
            color: null,
        },
        {
            key: 'cancellationbycustomer',
            name: translate.instant('notificationTypes.cancellationbycustomer'),
            color: null,
        },
        {
            key: 'cancellationbyinsurer',
            name: translate.instant('notificationTypes.cancellationbyinsurer'),
            color: null,
        },
        {
            key: 'futureterminationdate',
            name: translate.instant('notificationTypes.futureterminationdate'),
            color: null,
        },
        {
            key: 'cancellationDueToOldAge',
            name: translate.instant('notificationTypes.cancellationDueToOldAge'),
            color: null,
        },
        {
            key: 'additionalInsuredPersonPreRemoval',
            name: translate.instant('notificationTypes.additionalInsuredPersonPreRemoval'),
            color: null,
        },
        {
            key: 'additionalInsuredPersonRemoval',
            name: translate.instant('notificationTypes.additionalInsuredPersonRemoval'),
            color: null,
        },
        {
            key: 'coverChangeSuccess',
            name: translate.instant('notificationTypes.coverChangeSuccess'),
            color: null,
        },
        {
            key: 'newCountryTravelWarning',
            name: translate.instant('notificationTypes.newCountryTravelWarning'),
            color: null,
        },
        { key: 'updatemsisdn', name: translate.instant('notificationTypes.updatemsisdn'), color: null },
        {
            key: 'policyendorsementgeneral',
            name: translate.instant('notificationTypes.policyendorsementgeneral'),
            color: null,
        },
        {
            key: 'partialTravelWarning',
            name: translate.instant('notificationTypes.partialTravelWarning'),
            color: null,
        },
        {
            key: 'exceededtraveltermination',
            name: translate.instant('notificationTypes.exceededtraveltermination'),
            color: null,
        },
        {
            key: 'ExceededTravelTermination',
            name: translate.instant('notificationTypes.exceededtraveltermination'),
            color: null,
        },
        {
            key: 'contractSuspended',
            name: translate.instant('notificationTypes.contractSuspended'),
            color: null,
        },
        {
            key: 'deactivationFailedExpired',
            name: translate.instant('notificationTypes.deactivationFailedExpired'),
            color: null,
        },
        {
            key: 'contractSuspendedNoTravel',
            name: translate.instant('notificationTypes.contractSuspendedNoTravel'),
            color: null,
        },
        {
            key: 'proposalLegalInformation',
            name: translate.instant('notificationTypes.proposalLegalInformation'),
            color: null,
        },
        {
            key: 'PromotionExpiredNotification',
            name: translate.instant('notificationTypes.promotionExpiredNotification'),
            color: null,
        },
        {
            key: 'updateCover',
            name: translate.instant('notificationTypes.updateCover'),
            color: null,
        },
    ];
}
