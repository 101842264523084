import { inject, Injectable, signal } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { filter, from, Observable, of } from 'rxjs';
import { ClientInfo } from '../models';
import { Configuration } from '../models/configuration.model';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly keyCloakService = inject(KeycloakService);
    private readonly userService = inject(UserService);
    private readonly config = inject(Configuration);

    private _user = signal<User | null>(null);
    public user = this._user.asReadonly();

    private _client = signal<ClientInfo | null>(null);
    public client = this._client.asReadonly();

    private _allowedPartners = signal<string[]>([]);
    public allowedPartners = this._allowedPartners.asReadonly();

    public init() {
        const tokenInfo = this.keyCloakService.getKeycloakInstance().idTokenParsed;
        const user: User = {
            email: tokenInfo['email'],
            firstname: tokenInfo['given_name'],
            lastname: tokenInfo['family_name'],
        };
        this.userService.setUser(user);

        if (!this.config.production) {
            console.info('User info: ', user);
        }

        const client: ClientInfo = {
            name: tokenInfo['azp'],
            iss: tokenInfo['iss'],
            expiredTokenDate: new Date(tokenInfo['exp'] * 1000),
        };
        this._client.set(client);

        if (!this.config.production) {
            console.info('Client info: ', client);
        }

        this.handleOnTokenExpired();
    }

    public login(): Observable<void> {
        return from(this.keyCloakService.login());
    }

    public isLoggedIn(): Observable<boolean> {
        return of(this.keyCloakService.isLoggedIn());
    }

    public logout(): Observable<void> {
        return from(this.keyCloakService.logout(window.location.origin));
    }

    private handleOnTokenExpired() {
        this.keyCloakService.keycloakEvents$
            .pipe(filter((event) => event.type === KeycloakEventType.OnTokenExpired))
            .subscribe(async () => {
                console.info('Token expired. Updating token...');

                try {
                    await this.keyCloakService.updateToken(0);
                    console.info('Token updated successfully');
                } catch (error) {
                    console.error('Error updating token: ', error);
                    this.logout();
                }
            });
    }

    public setAllowedPartners(partners: string[]): void {
        this._allowedPartners.set(partners);
    }

    public getAllowedPartners(): string[] {
        return this._allowedPartners();
    }
}
