import { TransactionDto } from '../dtos';
import { sortByDate, sortByDateOnly } from './sort.util';

export function defaultTransactionsSorter(
    a: Pick<TransactionDto, 'start' | 'createdOn'>,
    b: Pick<TransactionDto, 'start' | 'createdOn'>,
) {
    const startOrder = sortByDateOnly(a.start, b.start, 1);
    return startOrder !== 0 ? startOrder : sortByDate(a.createdOn, b.createdOn, 1);
}
