import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';
import {
    mapOpenReversalDto,
    mapTransactionDto,
    OpenReversalDto,
    QueryResultDto,
    ReferenceDto,
    SendReversalsRequestDto,
    TransactionDto,
    TransactionReversalRequestDto,
    TransactionReversalResponseDto,
} from '../../dtos';
import { ProductCategory } from '../../enums';
import { Configuration } from '../../models';
import { defaultTransactionsSorter } from '../../utils/';
import { HttpClientAdapter } from '../http-client-adapter.service';

@Injectable({
    providedIn: 'root',
})
export class TransactionApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);

    public getTransactions(contractId: string): Observable<TransactionDto[]> {
        return this.http
            .get<TransactionDto[]>(`${this.config.baseUrl}Transactions/ListByContractId`, {
                params: { contractId: contractId },
            })
            .pipe(
                map((transactions) =>
                    transactions
                        .map((dto) => ({
                            dto: dto,
                            createdOn:
                                dto.createdOn != null ? DateTime.fromISO(dto.createdOn as never as string) : null,
                            start: dto.start != null ? DateTime.fromISO(dto.start as never as string) : null,
                        }))
                        .sort(defaultTransactionsSorter)
                        .map(({ dto }, index) => mapTransactionDto(dto, index)),
                ),
            );
    }

    public getTransactionsByReference(reference: ReferenceDto): Observable<TransactionDto[]> {
        return this.http
            .get<TransactionDto[]>(`${this.config.baseUrl}Transactions/GetAllByRef`, {
                params: { ...reference },
            })
            .pipe(
                map((transactions) =>
                    transactions
                        .map((dto) => ({
                            dto: dto,
                            createdOn:
                                dto.createdOn != null ? DateTime.fromISO(dto.createdOn as never as string) : null,
                            start: dto.start != null ? DateTime.fromISO(dto.start as never as string) : null,
                        }))
                        .sort(defaultTransactionsSorter)
                        .map(({ dto }, index) => mapTransactionDto(dto, index)),
                ),
            );
    }

    public getOpenReversals(): Observable<OpenReversalDto[]> {
        return this.http
            .get<QueryResultDto<OpenReversalDto>>(`${this.config.baseUrl}Transactions/QueryOpenReversals`)
            .pipe(map((result) => result.records.map((s) => mapOpenReversalDto(s))));
    }

    public sendApprovals(productCategory: ProductCategory, body: SendReversalsRequestDto[]): Observable<void> {
        return this.http.post(
            `${this.config.baseUrl}Transactions/ApproveReversalRequests?productCategory=${productCategory}`,
            {
                transactions: body,
            },
        );
    }

    public sendDenied(transactionIds: string[]): Observable<void> {
        return this.http.post(`${this.config.baseUrl}Transactions/RejectReversalRequests`, {
            transactionIds: transactionIds,
        });
    }

    public createReversal(request: TransactionReversalRequestDto): Observable<TransactionReversalResponseDto> {
        return this.http.post<TransactionReversalResponseDto>(
            `${this.config.baseUrl}transactions/CreateReversalRequests`,
            request,
        );
    }
}
