import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, map, mergeMap, Observable, of, startWith, switchMap } from 'rxjs';
import { DocumentContentDto, DocumentDto, mapDocumentDto } from '../../dtos';
import { Configuration, FileUploadNameStrategy, FileUploadStatus } from '../../models';
import { HumanizeFilesizePipe } from '../../pipes';
import { findUniqueFileName, getFileParts, megabyte, readFileAsBase64 } from '../../utils';
import { HttpClientAdapter } from '../http-client-adapter.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);
    private readonly humanizeFilesize = inject(HumanizeFilesizePipe);

    public getDocuments(refId: string, partnerId: string): Observable<DocumentDto[]> {
        return this.http
            .get<(DocumentDto & { name: string; extension: string })[]>(`${this.config.baseUrl}files/GetAll`, {
                params: {
                    refId: refId,
                    partnerId: partnerId,
                },
            })
            .pipe(map((response) => (response ?? []).map(mapDocumentDto)));
    }

    public checkIfDocumentsExists(refId: string, partnerId: string, fileName: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.config.baseUrl}files/CheckIfExists`, {
            params: {
                refId: refId,
                partnerId: partnerId,
                fileName: encodeURIComponent(fileName),
            },
        });
    }

    private uploadDocument(
        refId: string,
        partnerId: string,
        creator: string,
        fileName: string,
        file: File,
    ): Observable<DocumentDto> {
        const { name, extension } = getFileParts(fileName);

        return from(readFileAsBase64(file)).pipe(
            map((result) => result.split(';base64,')[1]),
            switchMap((base64) =>
                this.http.post<DocumentDto & { name: string; extension: string }>(
                    `${this.config.baseUrl}files/Upload`,
                    {
                        content: base64,
                        contentType: file.type,
                        creator: creator,
                        name: name,
                        extension: extension,
                        size: file.size,
                        refId: refId,
                        partnerId: partnerId,
                    },
                ),
            ),
            map(mapDocumentDto),
        );
    }

    public upload(
        refId: string,
        partnerId: string,
        file: File,
        creator: string,
        nameStrategy?: FileUploadNameStrategy,
    ): Observable<FileUploadStatus<DocumentDto>> {
        return findUniqueFileName({
            fileName: file.name,
            nameStrategy: nameStrategy,
            checkIfExists: (fileName: string) => this.checkIfDocumentsExists(refId, partnerId, fileName),
        }).pipe(
            mergeMap(({ exists, fileName }) => {
                // NOTE: Restrict file upload to 20mb as this is uploaded in one chunk and loaded into memory on the server
                //       This is a temporary solution until we implement chunked uploads (already done in new file upload e.g. invoices)
                const maxSize = megabyte(20);
                if (file.size > maxSize) {
                    return of<FileUploadStatus<DocumentDto>>({
                        status: 'failed',
                        error: this.translate.instant('fileUpload.fileTooLarge', {
                            maxSize: this.humanizeFilesize.transform(maxSize),
                        }),
                    });
                }

                if (exists && nameStrategy !== 'replace') {
                    return of<FileUploadStatus<DocumentDto>>({ status: 'file-exists' });
                }

                return this.uploadDocument(refId, partnerId, creator, fileName, file).pipe(
                    map<DocumentDto, FileUploadStatus<DocumentDto>>((doc) => ({ status: 'completed', result: doc })),
                    startWith<FileUploadStatus<DocumentDto>>({
                        status: 'uploading',
                        label: this.translate.instant('fileUpload.uploading'),
                    }),
                );
            }),
            startWith<FileUploadStatus<DocumentDto>>({
                status: 'uploading',
                label: this.translate.instant('fileUpload.checkFileName'),
            }),
        );
    }

    public download(document: DocumentDto): Observable<DocumentContentDto> {
        return this.http
            .get<{ content: string; contentType: string }>(`${this.config.baseUrl}files/Download`, {
                params: {
                    refId: document.refId,
                    partnerId: document.partnerId,
                    fileName: encodeURIComponent(document.fullName),
                },
            })
            .pipe(
                map((fileData) => ({
                    name: document.fullName,
                    content: fileData.content,
                    contentType: fileData.contentType,
                })),
            );
    }

    public remove(options: { refId: string; partnerId: string; fileName: string }): Observable<void> {
        return this.http.delete<void>(`${this.config.baseUrl}files/Delete`, {
            params: {
                refId: options.refId,
                partnerId: options.partnerId,
                fileName: encodeURIComponent(options.fileName),
            },
        });
    }

    public rename(document: DocumentDto, newName: string): Observable<DocumentDto> {
        const { name, extension } = getFileParts(document.fullName);

        return this.http
            .put<void>(
                `${this.config.baseUrl}files/Rename`,
                {},
                {
                    params: {
                        refId: document.refId,
                        partnerId: document.partnerId,
                        oldName: encodeURIComponent(`${name}.${extension}`),
                        newName: encodeURIComponent(newName),
                    },
                },
            )
            .pipe(
                map(() => ({
                    ...document,
                    fullName: newName,
                })),
            );
    }
}
