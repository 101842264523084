import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type AcceptanceState = 'FullyAccepted' | 'PartiallyAccepted' | 'Denied';

export function injectAcceptanceStates(): OverviewDto<AcceptanceState>[] {
    const translate = inject(TranslateService);

    return [
        {
            key: 'FullyAccepted',
            name: translate.instant('acceptanceStates.fullyAccepted'),
            color: 'green',
            icon: 'check-circle',
        },
        {
            key: 'PartiallyAccepted',
            name: translate.instant('acceptanceStates.partiallyAccepted'),
            color: 'yellow',
            icon: 'chart-pie',
        },
        {
            key: 'Denied',
            name: translate.instant('acceptanceStates.denied'),
            color: 'red',
            icon: 'times-circle',
        },
    ];
}
