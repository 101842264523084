import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageFn, ValidationMessageResolver } from '../../models';

export function withIbanValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);

        return new Map<string, ValidationMessageResolver>([
            ['iban', () => translate.instant('validation.ibanInvalid')],
        ]);
    };
}
