import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type ReversalReason =
    | 'ContractCancellation'
    | 'GoodwillOfInsurer'
    | 'ServiceNotConsumed'
    | 'TechnicalProblem'
    | 'AdministrativeError';

export function injectReversalReasons(): OverviewDto<ReversalReason>[] {
    const translate = inject(TranslateService);

    return [
        {
            key: 'ContractCancellation',
            name: translate.instant('reversal.reason.contractcancellation'),
            color: null,
        },
        {
            key: 'GoodwillOfInsurer',
            name: translate.instant('reversal.reason.goodwillofinsurer'),
            color: null,
        },
        {
            key: 'ServiceNotConsumed',
            name: translate.instant('reversal.reason.servicenotconsumed'),
            color: null,
        },
        { key: 'TechnicalProblem', name: translate.instant('reversal.reason.technicalproblem'), color: 'red' },
        {
            key: 'AdministrativeError',
            name: translate.instant('reversal.reason.administrativeerror'),
            color: null,
        },
    ];
}
