import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type Gender = 'Unknown' | 'Female' | 'Male' | 'Company';

export function injectGenders(): OverviewDto<Gender>[] {
    const translate = inject(TranslateService);

    return [
        { key: 'Unknown', name: translate.instant('genders.unknown'), color: null },
        { key: 'Female', name: translate.instant('genders.female'), color: null },
        { key: 'Male', name: translate.instant('genders.male'), color: null },
        { key: 'Company', name: translate.instant('genders.company'), color: null },
    ];
}
