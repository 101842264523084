import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';

export const allEmailsValidator: ValidatorFn = (control: AbstractControl) => {
    if (control.value == null || !Array.isArray(control.value)) {
        return null;
    }

    const emails = control.value as string[];
    const invalidEmail = emails.some((email) => Validators.email(new FormControl(email)) != null);

    return invalidEmail ? { email: true } : null;
};
