import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of } from 'rxjs';
import { CreateNoteDto, mapNoteDto, NoteDto, QuickNoteDto, UpdateNoteDto } from '../../dtos';
import { Configuration } from '../../models';
import { HttpClientAdapter } from '../http-client-adapter.service';

@Injectable({
    providedIn: 'root',
})
export class NoteApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);

    public getNotes(refId: string, refName: string): Observable<NoteDto[]> {
        return this.http
            .get<NoteDto[]>(`${this.config.bffBaseUrl}Notes/GetAllByReference`, {
                params: {
                    refId: refId,
                    refName: refName,
                },
            })
            .pipe(map((notes) => notes.map(mapNoteDto)));
    }

    public getQuickNotes(): Observable<QuickNoteDto[]> {
        return of([
            {
                color: 'yellow',
                label: this.translate.instant('notes.autofill.additionalinsured.chiptitle'),
                subject: this.translate.instant('notes.autofill.additionalinsured.subject'),
                body: this.translate.instant('notes.autofill.additionalinsured.body'),
            },
            {
                color: 'green',
                label: this.translate.instant('notes.autofill.maininusured.chiptitle'),
                subject: this.translate.instant('notes.autofill.maininusured.subject'),
                body: this.translate.instant('notes.autofill.maininusured.body'),
            },
            {
                color: 'orange',
                label: this.translate.instant('notes.autofill.customerdata.chiptitle'),
                subject: this.translate.instant('notes.autofill.customerdata.subject'),
                body: this.translate.instant('notes.autofill.customerdata.body'),
            },
            {
                color: 'orange',
                label: this.translate.instant('notes.autofill.changecover.chiptitle'),
                subject: this.translate.instant('notes.autofill.changecover.subject'),
                body: this.translate.instant('notes.autofill.changecover.body'),
            },
            {
                color: 'red',
                label: this.translate.instant('notes.autofill.cancellation.chiptitle'),
                subject: this.translate.instant('notes.autofill.cancellation.subject'),
                body: this.translate.instant('notes.autofill.cancellation.body'),
            },
            {
                color: 'purple',
                label: this.translate.instant('notes.autofill.claim.creation.chiptitle'),
                subject: this.translate.instant('notes.autofill.claim.creation.subject'),
                body: this.translate.instant('notes.autofill.claim.creation.body'),
            },
            {
                color: 'purple',
                label: this.translate.instant('notes.autofill.claim.statusupdate.chiptitle'),
                subject: this.translate.instant('notes.autofill.claim.statusupdate.subject'),
                body: this.translate.instant('notes.autofill.claim.statusupdate.body'),
            },
            {
                color: 'purple',
                label: this.translate.instant('notes.autofill.claim.dailyreceipt.chiptitle'),
                subject: this.translate.instant('notes.autofill.claim.dailyreceipt.subject'),
                body: this.translate.instant('notes.autofill.claim.dailyreceipt.body'),
            },
        ]);
    }

    public create(note: CreateNoteDto): Observable<string> {
        return this.http.post<string>(`${this.config.bffBaseUrl}Notes/Create`, {
            ...note,
        });
    }

    public update(note: UpdateNoteDto): Observable<string> {
        return this.http.patch<string>(`${this.config.bffBaseUrl}Notes/Update`, {
            ...note,
        });
    }

    public remove(noteId: string): Observable<void> {
        return this.http.delete<void>(`${this.config.bffBaseUrl}Notes/Delete`, {
            params: {
                id: noteId,
            },
        });
    }
}
