import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Configuration } from '../models/configuration.model';
import { AuthService } from './auth.service';
import { RolesService } from './roles.service';

export function provideKeycloakAuth(): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: KeycloakInitializer,
            deps: [KeycloakService, Configuration, AuthService, RolesService],
            multi: true,
        },
    ]);
}

function KeycloakInitializer(
    keycloak: KeycloakService,
    config: Configuration,
    authService: AuthService,
    rolesService: RolesService,
) {
    return async () => {
        await keycloak.init({
            config: {
                url: config.keycloak.url,
                realm: config.keycloak.realm,
                clientId: config.keycloak.clientId,
            },
            initOptions: {
                onLoad: 'login-required',
            },
            loadUserProfileAtStartUp: false,
            bearerExcludedUrls: ['/shared', '/assets', '/clients/public'],
        });

        if (!keycloak.isLoggedIn()) {
            await keycloak.login();
        }

        authService.init();
        rolesService.init();

        return true;
    };
}
