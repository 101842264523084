import { Component, input, output } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardComponent } from './card.component';

@Component({
    selector: 'nuis-error-card',
    standalone: true,
    imports: [TranslatePipe, ButtonModule, CardComponent],
    template: `
        <nuis-card styleClass="bg-white">
            <div class="flex flex-column align-items-center gap-4 p-3">
                <i class="pi pi-exclamation-triangle text-4xl text-red-500"></i>

                <div class="flex flex-column align-items-center gap-1">
                    <div class="text-xl font-bold text-red-500">{{ header() }}</div>
                    <div class="text-balance text-center text-red-500">{{ message() }}</div>
                </div>

                <p-button [label]="'actions.retry' | translate" severity="contrast" (click)="retry.emit()" />
            </div>
        </nuis-card>
    `,
})
export class ErrorCardComponent {
    public header = input.required<string>();
    public message = input.required<string>();
    public retry = output();
}
