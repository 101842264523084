import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
    ApproveInvoiceDto,
    CreateInvoiceDto,
    InvoiceDetailsDto,
    InvoiceDto,
    mapInvoiceDetailsDto,
    mapInvoiceDto,
    OverviewDto,
    UpdateInvoiceDto,
    UpdateOriginalsReceivedDto,
} from '../../dtos';
import { InvoiceState, StatusColor } from '../../enums';
import { Configuration } from '../../models';
import { HttpClientAdapter } from '../http-client-adapter.service';

@Injectable({
    providedIn: 'root',
})
export class InvoiceApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);

    public getInvoices(claimId: string): Observable<InvoiceDto[]> {
        return this.http
            .get<InvoiceDto[]>(`${this.config.bffBaseUrl}Invoices/GetForClaim`, {
                params: { claimId: claimId },
            })
            .pipe(map((invoices) => invoices.map((i) => mapInvoiceDto(i))));
    }

    public getInvoice(id: string): Observable<InvoiceDetailsDto | null> {
        return this.http
            .get<InvoiceDetailsDto>(`${this.config.bffBaseUrl}Invoices/GetDetails`, {
                params: { id: id },
            })
            .pipe(map(mapInvoiceDetailsDto));
    }

    public getInvoiceStates(): Observable<OverviewDto[]> {
        const colorMappings: Record<InvoiceState, StatusColor> = {
            Approved: 'green',
            Open: 'gray',
            ReadyForPayout: 'yellow',
            ExportedForPayout: 'orange',
            Paid: 'blue',
            Denied: 'red',
        };

        return this.http
            .get<OverviewDto[]>(`${this.config.bffBaseUrl}Invoices/States`)
            .pipe(
                map((invoiceStates) =>
                    invoiceStates.map((s) => ({ ...s, color: colorMappings[s.key as InvoiceState] })),
                ),
            );
    }

    public getInvoiceCategories(): Observable<OverviewDto[]> {
        return this.http.get<OverviewDto[]>(`${this.config.bffBaseUrl}Invoices/Categories`);
    }

    public createInvoice(invoice: CreateInvoiceDto): Observable<void> {
        return this.http.post(`${this.config.bffBaseUrl}Invoices/Create`, invoice);
    }

    public updateInvoice(invoice: UpdateInvoiceDto): Observable<void> {
        return this.http.put(`${this.config.bffBaseUrl}Invoices/Update`, invoice);
    }

    public releaseForPayoutInvoice(claimId: string, invoiceIds: string[]): Observable<void> {
        return this.http.put(`${this.config.bffBaseUrl}Invoices/ReleaseForPayout`, {
            claimId: claimId,
            invoiceIds: invoiceIds,
        });
    }

    public approveInvoice(invoice: ApproveInvoiceDto): Observable<void> {
        return this.http.put(`${this.config.bffBaseUrl}Invoices/Approve`, invoice);
    }

    public removeInvoice(id: string): Observable<void> {
        return this.http.delete(`${this.config.bffBaseUrl}Invoices/Delete`, {
            params: { id: id },
        });
    }

    public reopenInvoice(id: string): Observable<void> {
        return this.http.post(`${this.config.bffBaseUrl}Invoices/Reopen`, { id: id });
    }

    public updateOriginalsReceived(options: UpdateOriginalsReceivedDto): Observable<void> {
        return this.http.put(`${this.config.bffBaseUrl}Invoices/UpdateOriginalsReceived`, options);
    }

    public getBlobDownloadUrl(id: string): Observable<string> {
        return this.http
            .get<{ url: string }>(`${this.config.bffBaseUrl}Invoices/GetBlobDownloadUrl`, {
                params: { id: id },
            })
            .pipe(map((response) => response.url));
    }
}
