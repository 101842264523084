import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, throwError } from 'rxjs';
import { Configuration } from '../models/configuration.model';
import { UserService } from '../services/user.service';
import { newGuid } from '../utils';

export const authorizationHttpInterceptorFn: HttpInterceptorFn = (req, next) => {
    const config = inject(Configuration);
    const user = inject(UserService);
    const translate = inject(TranslateService);

    const onlyApplyOnUrls = [config.baseUrl, config.bffBaseUrl, config.financeBffBaseUrl];

    if (!onlyApplyOnUrls.some((url) => req.url.startsWith(url))) {
        return next(req);
    }

    const correlationId = req.headers.get('correlationId') ?? newGuid();

    const request = req.clone({
        headers: req.headers
            .set('subscription-key', config.subscriptionKey)
            .set('correlationId', correlationId)
            .set('userPrincipalId', user.userPrincipalId())
            .set('lang', translate.currentLang),
    });

    return next(request).pipe(
        catchError((error) => {
            console.error(error);
            return throwError(() => error);
        }),
    );
};
