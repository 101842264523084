import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConvertCurrencyRequestDto, MoneyDto } from '../../dtos';
import { OverviewDto } from '../../dtos/overview.dto';
import { Configuration } from '../../models';
import { HttpClientAdapter } from '../http-client-adapter.service';

@Injectable({
    providedIn: 'root',
})
export class CurrencyApiService {
    private readonly config = inject(Configuration);
    private readonly http = inject(HttpClientAdapter);

    public getAll(): Observable<OverviewDto[]> {
        return this.http.get<OverviewDto[]>(`${this.config.bffBaseUrl}Currencies/GetAll`);
    }

    public convertCurrency(dto: ConvertCurrencyRequestDto): Observable<MoneyDto> {
        return this.http.post<MoneyDto>(`${this.config.bffBaseUrl}Currencies/ConvertCurrency`, dto);
    }
}
