import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type ReversalStatus = 'Requested' | 'Approved' | 'Denied' | 'Finished';

export function injectReversalStatuses(): OverviewDto<ReversalStatus>[] {
    const translate = inject(TranslateService);

    return [
        { key: 'Requested', name: translate.instant('reversal.status.requested'), color: 'gray' },
        { key: 'Approved', name: translate.instant('reversal.status.approved'), color: 'orange' },
        { key: 'Denied', name: translate.instant('reversal.status.denied'), color: 'red' },
        { key: 'Finished', name: translate.instant('reversal.status.finished'), color: 'green' },
    ];
}
