import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type NotificationStatus = 'Sent' | 'SentByPartner' | 'Open' | 'Failed';

export function injectNotificationStatuses(): OverviewDto<NotificationStatus>[] {
    const translate = inject(TranslateService);
    return [
        { key: 'Sent', name: translate.instant('notification.status.sent'), color: 'gray' },
        { key: 'SentByPartner', name: translate.instant('notification.status.sentByPartner'), color: 'gray' },
        { key: 'Open', name: translate.instant('notification.status.open'), color: 'orange' },
        { key: 'Failed', name: translate.instant('notification.status.failed'), color: 'red' },
    ];
}
