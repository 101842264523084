import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto } from '../dtos';

export type AuditField =
    | 'ContractId'
    | 'PolicyNumber'
    | 'CancellationReason'
    | 'CancellationReceived'
    | 'ContractTypeId'
    | 'CoverTo'
    | 'CoverFrom'
    | 'Created'
    | 'Discriminiator'
    | 'Discount'
    | 'LapsedOn'
    | 'LastModified'
    | 'MinimumTermEndsOn'
    | 'PartnerKey'
    | 'Premium'
    | 'PromotionCode'
    | 'Status'
    | 'SubscriptionDate'
    | 'TerminationOn'
    | 'TrackingCode'
    | 'UserId'
    | 'VerificationMethod'
    | 'InsuredRisk'
    | 'AdditionalInformation_CaseId'
    | 'AdditionalInformation_RetailerId'
    | 'AdditionalInformation_SalesPerson'
    | 'AdditionalInformation_TariffId'
    | 'AdditionalInformation_TariffName'
    | 'DateOfBirth'
    | 'FirstName'
    | 'LastName'
    | 'Gender'
    | 'MainInsuredPerson_TitleSuffix'
    | 'MainInsuredPerson_TitlePrefix'
    | 'MainInsuredPerson_DateOfBirth'
    | 'MainInsuredPerson_Email'
    | 'MainInsuredPerson_FirstName'
    | 'MainInsuredPerson_Gender'
    | 'MainInsuredPerson_LastName'
    | 'MainInsuredPerson_NationalIdentificationNumber'
    | 'MainInsuredPerson_PhoneNumber'
    | 'MainInsuredPerson_Address_AddressLine1'
    | 'MainInsuredPerson_Address_AddressLine2'
    | 'MainInsuredPerson_Address_City'
    | 'MainInsuredPerson_Address_Country'
    | 'MainInsuredPerson_Address_PostalCode'
    | 'MainInsuredPerson_PersonType'
    | 'MainInsuredPerson_PassportNumber'
    | 'MainInsuredPerson_Id'
    | 'NationalIdentificationNumber'
    | 'PassportIssuingAuthority'
    | 'PassportNumber'
    | 'PassportPlaceOfIssue'
    | 'Identifier'
    | 'Indicator'
    | 'Contractid'
    | 'Date'
    | 'LastRoamingInfo'
    | 'LeftHomeCountry'
    | 'AdditionalInformation'
    | 'CreatedBy'
    | 'LastModifiedBy'
    | 'id'
    | 'AdditionalInsuredPersons'
    | 'TravelState'
    | 'TravelSubState'
    | 'InsuranceType'
    | 'PaymentState'
    | 'Name'
    | 'PartnerId'
    | 'ParnterId'
    | 'StartedOn'
    | 'InsuranceStart'
    | 'EndsOn'
    | 'DeactivatedOn'
    | 'DeactivationSource'
    | 'EndReason'
    | 'Countries'
    | 'TicketId'
    | 'EstimatedAdditionalCosts'
    | 'EstimatedCosts'
    | 'ActualCosts'
    | 'ActualAdditionalCosts'
    | 'Description'
    | 'State'
    | 'ClaimNumber'
    | 'CLaimOn'
    | 'Additional'
    | 'LossEvent_Name'
    | 'LossType_Name'
    | 'Caller_FirstName'
    | 'Caller_LastName'
    | 'Caller_Title'
    | 'Caller_Gender'
    | 'Caller_Email'
    | 'Caller_PhoneNumber'
    | 'ClaimPerson_FirstName'
    | 'ClaimPerson_LastName'
    | 'ClaimPerson_Title'
    | 'ClaimPerson_Gender'
    | 'ClaimPerson_Email'
    | 'ClaimPerson_DateOfBirth'
    | 'ClaimPerson_NationalIdentificationNumber'
    | 'ClaimPerson_PhoneNumber'
    | 'ClaimPerson_City'
    | 'ClaimPerson_Country'
    | 'ClaimPerson_PostalCode'
    | 'ClaimPerson_AddressLine1'
    | 'AssisteurNumber'
    | 'LossEvent_Id'
    | 'LossType_Id'
    | 'EndorsementNumber'
    | 'Transmitter'
    | 'ExecutedAgentEmail'
    | 'CancelationReceived'
    | 'CancelationReason'
    | 'CancelationProcessedOn'
    | 'CreatedOn'
    | 'ModifiedOn'
    | 'RefId'
    | 'RefName'
    | 'PaymentDoneOn'
    | 'PostalCode'
    | 'Addressline1'
    | 'Addressline2'
    | 'City'
    | 'CompanyName'
    | 'Country'
    | 'PartnerReference'
    | 'PhoneNumber'
    | 'TitlePrefix'
    | 'TitleSuffix';

export function injectAuditFields(): OverviewDto<AuditField>[] {
    const translate = inject(TranslateService);
    return [
        { key: 'ContractId', name: translate.instant('auditField.ContractId'), color: null },
        { key: 'PolicyNumber', name: translate.instant('auditField.PolicyNumber'), color: null },
        { key: 'CancellationReason', name: translate.instant('auditField.CancellationReason'), color: null },
        {
            key: 'CancellationReceived',
            name: translate.instant('auditField.CancellationReceived'),
            color: null,
        },
        { key: 'ContractTypeId', name: translate.instant('auditField.ContractTypeId'), color: null },
        { key: 'CoverTo', name: translate.instant('auditField.CoverTo'), color: null },
        { key: 'CoverFrom', name: translate.instant('auditField.CoverFrom'), color: null },
        { key: 'Created', name: translate.instant('auditField.Created'), color: null },
        { key: 'Discriminiator', name: translate.instant('auditField.Discriminiator'), color: null },
        { key: 'Discount', name: translate.instant('auditField.Discount'), color: null },
        { key: 'LapsedOn', name: translate.instant('auditField.LapsedOn'), color: null },
        { key: 'LastModified', name: translate.instant('auditField.LastModified'), color: null },
        { key: 'MinimumTermEndsOn', name: translate.instant('auditField.MinimumTermEndsOn'), color: null },
        { key: 'PartnerKey', name: translate.instant('auditField.PartnerKey'), color: null },
        { key: 'Premium', name: translate.instant('auditField.Premium'), color: null },
        { key: 'PromotionCode', name: translate.instant('auditField.PromotionCode'), color: null },
        { key: 'Status', name: translate.instant('auditField.Status'), color: null },
        { key: 'SubscriptionDate', name: translate.instant('auditField.SubscriptionDate'), color: null },
        { key: 'TerminationOn', name: translate.instant('auditField.TerminationOn'), color: null },
        { key: 'TrackingCode', name: translate.instant('auditField.TrackingCode'), color: null },
        { key: 'UserId', name: translate.instant('auditField.UserId'), color: null },
        { key: 'VerificationMethod', name: translate.instant('auditField.VerificationMethod'), color: null },
        { key: 'InsuredRisk', name: translate.instant('auditField.InsuredRisk'), color: null },
        {
            key: 'AdditionalInformation_CaseId',
            name: translate.instant('auditField.AdditionalInformation_CaseId'),
            color: null,
        },
        {
            key: 'AdditionalInformation_RetailerId',
            name: translate.instant('auditField.AdditionalInformation_RetailerId'),
            color: null,
        },
        {
            key: 'AdditionalInformation_SalesPerson',
            name: translate.instant('auditField.AdditionalInformation_SalesPerson'),
            color: null,
        },
        {
            key: 'AdditionalInformation_TariffId',
            name: translate.instant('auditField.AdditionalInformation_TariffId'),
            color: null,
        },
        {
            key: 'AdditionalInformation_TariffName',
            name: translate.instant('auditField.AdditionalInformation_TariffName'),
            color: null,
        },
        { key: 'DateOfBirth', name: translate.instant('auditField.DateOfBirth'), color: null },
        { key: 'FirstName', name: translate.instant('auditField.FirstName'), color: null },
        { key: 'LastName', name: translate.instant('auditField.LastName'), color: null },
        { key: 'Gender', name: translate.instant('auditField.Gender'), color: null },
        {
            key: 'MainInsuredPerson_TitleSuffix',
            name: translate.instant('auditField.MainInsuredPerson_TitleSuffix'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_TitlePrefix',
            name: translate.instant('auditField.MainInsuredPerson_TitlePrefix'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_DateOfBirth',
            name: translate.instant('auditField.MainInsuredPerson_DateOfBirth'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_Email',
            name: translate.instant('auditField.MainInsuredPerson_Email'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_FirstName',
            name: translate.instant('auditField.MainInsuredPerson_FirstName'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_Gender',
            name: translate.instant('auditField.MainInsuredPerson_Gender'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_LastName',
            name: translate.instant('auditField.MainInsuredPerson_LastName'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_NationalIdentificationNumber',
            name: translate.instant('auditField.MainInsuredPerson_NationalIdentificationNumber'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_PhoneNumber',
            name: translate.instant('auditField.MainInsuredPerson_PhoneNumber'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_Address_AddressLine1',
            name: translate.instant('auditField.MainInsuredPerson_Address_AddressLine1'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_Address_AddressLine2',
            name: translate.instant('auditField.MainInsuredPerson_Address_AddressLine2'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_Address_City',
            name: translate.instant('auditField.MainInsuredPerson_Address_City'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_Address_Country',
            name: translate.instant('auditField.MainInsuredPerson_Address_Country'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_Address_PostalCode',
            name: translate.instant('auditField.MainInsuredPerson_Address_PostalCode'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_PersonType',
            name: translate.instant('auditField.MainInsuredPerson_PersonType'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_PassportNumber',
            name: translate.instant('auditField.MainInsuredPerson_PassportNumber'),
            color: null,
        },
        {
            key: 'MainInsuredPerson_Id',
            name: translate.instant('auditField.MainInsuredPerson_Id'),
            color: null,
        },
        {
            key: 'NationalIdentificationNumber',
            name: translate.instant('auditField.NationalIdentificationNumber'),
            color: null,
        },
        {
            key: 'PassportIssuingAuthority',
            name: translate.instant('auditField.PassportIssuingAuthority'),
            color: null,
        },
        { key: 'PassportNumber', name: translate.instant('auditField.PassportNumber'), color: null },
        { key: 'PassportPlaceOfIssue', name: translate.instant('auditField.PassportPlaceOfIssue'), color: null },
        { key: 'Identifier', name: translate.instant('auditField.Identifier'), color: null },
        { key: 'Indicator', name: translate.instant('auditField.Indicator'), color: null },
        { key: 'Contractid', name: translate.instant('auditField.Contractid'), color: null },
        { key: 'Date', name: translate.instant('auditField.Date'), color: null },
        { key: 'LastRoamingInfo', name: translate.instant('auditField.LastRoamingInfo'), color: null },
        { key: 'LeftHomeCountry', name: translate.instant('auditField.LeftHomeCountry'), color: null },
        { key: 'AdditionalInformation', name: translate.instant('auditField.AdditionalInformation'), color: null },
        { key: 'CreatedBy', name: translate.instant('auditField.CreatedBy'), color: null },
        { key: 'LastModifiedBy', name: translate.instant('auditField.LastModifiedBy'), color: null },
        { key: 'Discount', name: translate.instant('auditField.Discount'), color: null },
        { key: 'id', name: translate.instant('auditField.id'), color: null },
        {
            key: 'AdditionalInsuredPersons',
            name: translate.instant('additionalInsuredPersons'),
            color: null,
        },
        { key: 'TravelState', name: translate.instant('auditField.TravelState'), color: null },
        { key: 'TravelSubState', name: translate.instant('auditField.TravelSubState'), color: null },
        { key: 'InsuranceType', name: translate.instant('auditField.InsuranceType'), color: null },
        { key: 'PaymentState', name: translate.instant('auditField.PaymentState'), color: null },
        { key: 'Name', name: translate.instant('auditField.Name'), color: null },
        { key: 'PartnerId', name: translate.instant('auditField.PartnerId'), color: null },
        { key: 'ParnterId', name: translate.instant('auditField.ParnterId'), color: null },
        { key: 'StartedOn', name: translate.instant('auditField.StartedOn'), color: null },
        { key: 'InsuranceStart', name: translate.instant('auditField.InsuranceStart'), color: null },
        { key: 'EndsOn', name: translate.instant('auditField.EndsOn'), color: null },
        { key: 'DeactivatedOn', name: translate.instant('auditField.DeactivatedOn'), color: null },
        { key: 'DeactivationSource', name: translate.instant('auditField.DeactivationSource'), color: null },
        { key: 'EndReason', name: translate.instant('auditField.EndReason'), color: null },
        { key: 'Countries', name: translate.instant('auditField.Countries'), color: null },
        { key: 'TicketId', name: translate.instant('auditField.TicketId'), color: null },
        {
            key: 'EstimatedAdditionalCosts',
            name: translate.instant('auditField.EstimatedAdditionalCosts'),
            color: null,
        },
        { key: 'EstimatedCosts', name: translate.instant('auditField.EstimatedCosts'), color: null },
        { key: 'ActualCosts', name: translate.instant('auditField.ActualCosts'), color: null },
        {
            key: 'ActualAdditionalCosts',
            name: translate.instant('auditField.ActualAdditionalCosts'),
            color: null,
        },
        { key: 'Description', name: translate.instant('auditField.Description'), color: null },
        { key: 'State', name: translate.instant('auditField.State'), color: null },
        { key: 'ClaimNumber', name: translate.instant('auditField.ClaimNumber'), color: null },
        { key: 'CLaimOn', name: translate.instant('auditField.CLaimOn'), color: null },
        { key: 'Additional', name: translate.instant('auditField.Additional'), color: null },
        { key: 'LossEvent_Name', name: translate.instant('auditField.LossEvent_Name'), color: null },
        { key: 'LossType_Name', name: translate.instant('auditField.LossType_Name'), color: null },
        { key: 'Caller_FirstName', name: translate.instant('auditField.Caller_FirstName'), color: null },
        { key: 'Caller_LastName', name: translate.instant('auditField.Caller_LastName'), color: null },
        { key: 'Caller_Title', name: translate.instant('auditField.Caller_Title'), color: null },
        { key: 'Caller_Gender', name: translate.instant('auditField.Caller_Gender'), color: null },
        { key: 'Caller_Email', name: translate.instant('auditField.Caller_Email'), color: null },
        { key: 'Caller_PhoneNumber', name: translate.instant('auditField.Caller_PhoneNumber'), color: null },
        {
            key: 'ClaimPerson_FirstName',
            name: translate.instant('auditField.ClaimPerson_FirstName'),
            color: null,
        },
        {
            key: 'ClaimPerson_LastName',
            name: translate.instant('auditField.ClaimPerson_LastName'),
            color: null,
        },
        { key: 'ClaimPerson_Title', name: translate.instant('auditField.ClaimPerson_Title'), color: null },
        { key: 'ClaimPerson_Gender', name: translate.instant('auditField.ClaimPerson_Gender'), color: null },
        { key: 'ClaimPerson_Email', name: translate.instant('auditField.ClaimPerson_Email'), color: null },
        {
            key: 'ClaimPerson_DateOfBirth',
            name: translate.instant('auditField.ClaimPerson_DateOfBirth'),
            color: null,
        },
        {
            key: 'ClaimPerson_NationalIdentificationNumber',
            name: translate.instant('auditField.ClaimPerson_NationalIdentificationNumber'),
            color: null,
        },
        {
            key: 'ClaimPerson_PhoneNumber',
            name: translate.instant('auditField.ClaimPerson_PhoneNumber'),
            color: null,
        },
        {
            key: 'ClaimPerson_City',
            name: translate.instant('auditField.ClaimPerson_City'),
            color: null,
        },
        {
            key: 'ClaimPerson_Country',
            name: translate.instant('auditField.ClaimPerson_Country'),
            color: null,
        },
        {
            key: 'ClaimPerson_PostalCode',
            name: translate.instant('auditField.ClaimPerson_PostalCode'),
            color: null,
        },
        {
            key: 'ClaimPerson_AddressLine1',
            name: translate.instant('auditField.ClaimPerson_AddressLine1'),
            color: null,
        },
        { key: 'AssisteurNumber', name: translate.instant('auditField.AssisteurNumber'), color: null },
        { key: 'LossEvent_Id', name: translate.instant('auditField.LossEvent_Id'), color: null },
        { key: 'LossType_Id', name: translate.instant('auditField.LossType_Id'), color: null },
        { key: 'EndorsementNumber', name: translate.instant('auditField.EndorsementNumber'), color: null },
        { key: 'Transmitter', name: translate.instant('auditField.Transmitter'), color: null },
        { key: 'ExecutedAgentEmail', name: translate.instant('auditField.ExecutedAgentEmail'), color: null },
        { key: 'CancelationReceived', name: translate.instant('auditField.CancelationReceived'), color: null },
        { key: 'CancelationReason', name: translate.instant('auditField.CancelationReason'), color: null },
        {
            key: 'CancelationProcessedOn',
            name: translate.instant('auditField.CancelationProcessedOn'),
            color: null,
        },
        { key: 'CreatedOn', name: translate.instant('auditField.CreatedOn'), color: null },
        { key: 'ModifiedOn', name: translate.instant('auditField.ModifiedOn'), color: null },
        { key: 'RefId', name: translate.instant('auditField.RefId'), color: null },
        { key: 'RefName', name: translate.instant('auditField.RefName'), color: null },
        { key: 'PaymentDoneOn', name: translate.instant('auditField.PaymentDoneOn'), color: null },
        { key: 'PostalCode', name: translate.instant('auditField.PostalCode'), color: null },
        { key: 'Addressline1', name: translate.instant('auditField.Addressline1'), color: null },
        { key: 'Addressline2', name: translate.instant('auditField.Addressline2'), color: null },
        { key: 'City', name: translate.instant('auditField.City'), color: null },
        { key: 'CompanyName', name: translate.instant('auditField.CompanyName'), color: null },
        { key: 'Country', name: translate.instant('auditField.Country'), color: null },
        { key: 'PartnerReference', name: translate.instant('auditField.PartnerReference'), color: null },
        { key: 'PhoneNumber', name: translate.instant('auditField.PhoneNumber'), color: null },
        { key: 'TitlePrefix', name: translate.instant('auditField.TitlePrefix'), color: null },
        { key: 'TitleSuffix', name: translate.instant('auditField.TitleSuffix'), color: null },
    ];
}
