import { inject, Pipe, PipeTransform } from '@angular/core';
import { PartnerIdKeyPipe } from './partner-id-key.pipe';

@Pipe({
    name: 'partnerCurrency',
    standalone: true,
})
export class PartnerCurrencyPipe implements PipeTransform {
    private readonly partnerIdKeyPipe = inject(PartnerIdKeyPipe);

    public transform(partnerId: string): string {
        if (this.partnerIdKeyPipe.transform(partnerId) == 'a1srb') {
            return 'RSD';
        } else if (this.partnerIdKeyPipe.transform(partnerId) == 'a1bgr') {
            return 'BGN';
        } else {
            return 'EUR';
        }
    }
}
