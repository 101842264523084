import { DateTime } from 'luxon';
import { Gender } from '../enums';
import { AddressDto } from './address.dto';

export interface PersonDto {
    firstName: string | null;
    lastName: string | null;
    dateOfBirth: DateTime | null;
    email: string | null;
    gender: Gender | null;
    phoneNumber: string | null;
    nationalIdentificationNumber: string | null;

    address: AddressDto | null;
}

export function mapPersonDto(dto: PersonDto): PersonDto {
    return {
        ...dto,
        dateOfBirth: dto.dateOfBirth ? DateTime.fromISO(dto.dateOfBirth as never as string, { zone: 'utc' }) : null,
    };
}
