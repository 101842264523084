import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'countryCrop',
    standalone: true,
})
export class CountryCropPipe implements PipeTransform {
    private readonly maxCountries = 5;

    public transform(countryString: string | null): string {
        if (countryString == null) {
            return '';
        }

        const countries = countryString.split(' - ');
        if (countries.length <= this.maxCountries) {
            return countryString;
        }

        return this.shortenCountryString(countries, this.maxCountries);
    }

    private shortenCountryString(countries: string[], maxCountries: number): string {
        const countryCrop = `${countries[0]} ... `;
        const countryCropArr = countries.slice(-(maxCountries - 1)); // NOTE: Get last (x - 1) countries
        const countryEndCrop = countryCropArr.join(' - ');

        return `${countryCrop}${countryEndCrop}`;
    }
}
